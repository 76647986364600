import { behaviorSettingsProps } from './prop-types';

const generateClassNameByBehaviorSettings = (behaviorSettings) => {
  if (!behaviorSettings) {
    return '';
  }

  const classes = Object.keys(behaviorSettings).map((setting) => {
    switch (setting) {
      case 'cw_background_color': {
        const color = behaviorSettings['cw_background_color'].color || 'default';
        return `behaviour-settings__background-color--${color}`;
      }
      case 'cw_outer_spacing': {
        const topSpacing = behaviorSettings['cw_outer_spacing']['top_spacing'] || 'standard';
        const bottomSpacing = behaviorSettings['cw_outer_spacing']['bottom_spacing'] || 'standard';
        return `behaviour-settings__top-spacing--${topSpacing} behaviour-settings__bottom-spacing--${bottomSpacing}`;
      }
      case 'cw_experiment_variant': {
        const variant = behaviorSettings['cw_experiment_variant'].variant || '';
        return variant ? `behaviour-settings__ab-variant--variant-${variant}` : '';
      }
      // Place for other behavior settings.
      default:
        return '';
    }
  });

  return classes.join(' ');
};

export { generateClassNameByBehaviorSettings, behaviorSettingsProps };
